const TvNoise = function(){

    var mainHeader = document.getElementById('main-header');
    var canvas = mainHeader.createElementInside('canvas').addAttr('id', 'tv-noise').setStyle('position', 'absolute');
    var ctx = canvas.getContext('2d');


    this.draw = function(){
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let i, x, y, w, h;
        for(i = 0; i < 3000; i++){
            ctx.fillStyle = 'rgba(255, 255, 255, ' + ((Math.random() * 0.1) + 0.05) + ')';
            x = Math.floor(Math.random()  * canvas.width);
            y = Math.floor(Math.random()  * canvas.height);
            w = Math.floor(Math.random()  * 4) + 6;
            h = Math.floor(Math.random()  * 2) + 1;
            ctx.fillRect(x, y, w, h);
        }
    }
    this.scale = function(){
        var box = mainHeader.offset();
        canvas.addAttr('width', box.width + 'px').addAttr('height', box.height + 'px');
        this.draw();
    }
    window.addEventListener('resize', this.scale);
    this.scale();
    this.draw();
    setInterval(this.draw, 40);
}

window.tvNoise = new TvNoise();
